import { useTenantConfig } from "@App/hooks";
import { UserPreferenceMutationParams } from "@App/hooks/api/events/UserPreferenceMutationParams";
import useUpdateEventUserPreference from "@App/hooks/api/events/useUpdateEventUserPreference";
import { Event } from "@App/models/event";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const EventItemLogic = (event: Event, isFetchingEvents?: boolean) => {
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;
  const tenantConfig = useTenantConfig();

  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] =
    useState(false);

  const eventStartDate = useMemo(
    () => new Date(event.startDate),
    [event.startDate],
  );

  const handleOnMutation = (params: UserPreferenceMutationParams) => {
    if (params.preference.name === "isRSVPd") {
      setIsRsvpPreferenceProcessing(true);
    } else {
      setIsFavPreferenceProcessing(true);
    }
  };

  useEffect(() => {
    if (!isFetchingEvents) {
      setIsFavPreferenceProcessing(false);
      setIsRsvpPreferenceProcessing(false);
    }
  }, [isFetchingEvents]);

  const userPreferenceMutation = useUpdateEventUserPreference({
    eventTitle: event.title,
    onMutate: handleOnMutation,
  });

  const updateEventPreference = (
    preferenceName: "isFavorite" | "isRSVPd" | "isAttending",
    preferenceValue: boolean,
  ) => {
    if (!userInfo?.id) return;

    const mutationParams = {
      eventId: event.id,
      userId: userInfo.id,
      preference: {
        name: preferenceName,
        value: preferenceValue,
      },
    };

    userPreferenceMutation.mutate(mutationParams);
  };

  const detailsPath = `/calendar/event-details/${event.id}`;

  return {
    eventStartDate,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    areCommunityUserAccountsDisabled:
      tenantConfig?.featureToggles?.areCommunityUserAccountsDisabled,
    updateEventPreference,
    detailsPath,
  };
};

export default EventItemLogic;
