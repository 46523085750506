import { addWaiver, getWaiverById } from "@App/api/waiver";
import { SHOW_REGISTRATION_DETAILS } from "@App/constants/userConstants";
import { useEventCategories } from "@App/hooks";
import useEventDetails from "@App/hooks/api/events/useEventDetails";
import { UserPreferenceMutationParams } from "@App/hooks/api/events/UserPreferenceMutationParams";
import useUpdateEventUserPreference from "@App/hooks/api/events/useUpdateEventUserPreference";
import { UserType } from "@App/models/types";
import { Waiver } from "@App/models/waiver";
import { handleNotLoggedUserLogin } from "@App/store/actions/userActions";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { getEventDateOrRange, getEventTimeRange } from "@Utils/utils";
import { parseISO, startOfToday } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const EventDetailsLogic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams();

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  const [waiverItems, setWaiverItems] = useState<Waiver[] | null>(null);

  const [isLoadingWaivers, setIsLoadingWaivers] = useState(false); // eslint-disable-line
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] =
    useState(false);
  const [isTicketPurchaseModalOpen, setIsTicketPurchaseModalOpen] =
    useState(false);
  const [isWaiversModalOpen, setIsWaiversModalOpen] = useState(false);
  const [showCTAs, setShowCTAs] = useState(true);

  const { eventCategories } = useEventCategories();

  const { event, isLoadingEvent } = useEventDetails({ eventId });

  // TODO: Just include the name of the category in the response... Sheesh.
  const currentEventCategories = useMemo(() => {
    if (!eventCategories || !event?.categoryIds) return [];

    return eventCategories?.filter((category) => {
      event?.categoryIds?.includes(category.id);
    });
  }, [eventCategories, event]);

  const eventDateString = useMemo(() => {
    if (!event) return "";

    return getEventDateOrRange(event);
  }, [event]);

  const eventTimeString = useMemo(() => {
    if (!event) return "";

    return getEventTimeRange(event);
  }, [event]);

  // Hide CTAs if the event has ended
  useEffect(() => {
    if (!event) return;

    let endDateString: string;

    if (event.recurring?.isRecurring && event.recurring?.terminationDate) {
      endDateString = event.recurring.terminationDate;
    } else {
      endDateString = event.endDate;
    }

    const endDate = parseISO(endDateString);
    const now = startOfToday();

    setShowCTAs(now < endDate);
  }, [event]);

  const userType = useMemo(() => userInfo?.userType, [userInfo]);

  // TODO: Handle with tanstack
  const postWaivers = () => {
    if (!waiverItems?.length) return;
    const waiverReq = [];
    for (const waiver of waiverItems!) {
      const req = addWaiver({
        eventId: event!.id,
        waiverId: waiver.id,
        userId: userInfo!.id,
      });
      waiverReq.push(req);
    }
    if (waiverReq.length) {
      Promise.all(waiverReq);
    }
  };

  const handleOnMutate = (params: UserPreferenceMutationParams) => {
    if (params.preference.name === "isRSVPd") {
      setIsRsvpPreferenceProcessing(true);
    } else {
      setIsFavPreferenceProcessing(true);
    }
  };

  const handleOnSuccess = (params: UserPreferenceMutationParams) => {
    if (params.preference.name === "isRSVPd") {
      setIsRsvpPreferenceProcessing(false);
    } else {
      setIsFavPreferenceProcessing;
    }
  };

  const updateEventPreferenceMutation = useUpdateEventUserPreference({
    eventTitle: event?.title ?? "",
    onMutate: handleOnMutate,
    onSuccess: handleOnSuccess,
  });

  // TODO: Handle with tanstack
  const updateEventPreference = async (
    preferenceName: "isFavorite" | "isRSVPd" | "isAttending",
    preferenceValue: boolean,
  ) => {
    if (!userInfo?.id || !event) return;

    const mutationParams = {
      eventId: event.id,
      userId: userInfo.id,
      preference: {
        name: preferenceName,
        value: preferenceValue,
      },
    };

    updateEventPreferenceMutation.mutate(mutationParams);
  };

  // TODO: Handle with tanstack
  useEffect(() => {
    if (!event?.waiverIds?.length) return;

    setIsLoadingWaivers(true);
    const getWaiversAsync = async () => {
      const waivers: Waiver[] = [];
      for await (const waiverId of event?.waiverIds!) {
        const response = await getWaiverById(waiverId);
        waivers.push(response.data);
      }
      return waivers;
    };
    getWaiversAsync().then((waivers) => {
      setWaiverItems(waivers);
      setIsLoadingWaivers(false);
    });
  }, [event?.waiverIds, setIsLoadingWaivers]);

  const ticketingCtaLabel = useMemo(() => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        return t("Purchase Tickets");
      }
      if (userType === UserType.Initiated) {
        return "Please Complete Account Setup";
      }
      if (userType === UserType.ResidentPending) {
        return "Resident Verification Pending";
      }
    }
    return t("Log In to Purchase");
  }, [userType]);

  const isCTAButtonDisabled = userType === UserType.ResidentPending;

  // TODO: For use with unused TicketPurchaseModal
  // const handleTicketPurchase = (eventId?: string) => {
  //   if (eventId) {
  //     navigate(`/calendar/event-details/${eventId}`);
  //   }

  //   // TODO: Why the hell does this set the rsvp processing to true?
  //   setIsRsvpPreferenceProcessing(true);
  //   setIsTicketPurchaseModalOpen(false);
  // };

  const goToTicketingPayment = () => {
    if (event) {
      navigate(`/calendar/event-details/tickets?id=${event.id}`);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleWaiversClose = async () => {
    await updateEventPreference("isRSVPd", true);
    postWaivers();
    setIsWaiversModalOpen(false);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  const handleCTAButton = () => {
    // Since Initiated = 0, we need to check if it's not undefined
    if (
      (!!userType || userType === UserType.Initiated) &&
      userType !== UserType.ResidentPending
    ) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        goToTicketingPayment();
      }

      if (userType === UserType.Initiated) {
        showAccountDetails();
      }
      return;
    }

    handleNotLoggedUserLogin();
  };

  return {
    event,
    isLoadingEvent,
    eventDateString,
    eventTimeString,
    currentEventCategories,
    waiverItems,
    userInfo,
    ticketingCtaLabel,

    showCTAs,
    isUserLogged: !!userInfo?.id,
    isCTAButtonDisabled,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,

    isTicketPurchaseModalOpen,
    setIsTicketPurchaseModalOpen,
    isWaiversModalOpen,
    setIsWaiversModalOpen,

    goBack,
    updateEventPreference,
    // handleTicketPurchase,
    goToTicketingPayment,
    handleWaiversClose,
    handleCTAButton,
  };
};

export default EventDetailsLogic;
