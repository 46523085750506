import { searchPagedEvents } from "@App/api/event";
import { DEFAULT_EVENTS_PAGE_SIZE } from "@App/constants/eventConstants";
import { QUERY_KEY_EVENTS_SEARCH } from "@App/constants/queryKeyConstants";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import getFormattedDateRange from "./getFormattedDateRange";
import { UseEventsSearchParams } from "./UseEventsSearchParams";

// Don't use the month range if there's a selected date
export default function useEventsSearch(
  params: UseEventsSearchParams,
  isEnabled: boolean,
  useDefaultStartDate = true,
) {
  const queryParams = {
    ...params,
    managedById: params.managedById === null ? undefined : params.managedById,
    ...getFormattedDateRange(
      params.startDate,
      params.endDate,
      useDefaultStartDate,
    ),
  };

  const queryKeyParams = Object.values({
    ...params,
    startDate: queryParams.startDate,
    endDate: queryParams.endDate,
  });

  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_EVENTS_SEARCH, ...queryKeyParams],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await searchPagedEvents({
        page: pageParam,
        pageSize: DEFAULT_EVENTS_PAGE_SIZE,
        ...queryParams,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.isLastPage) {
        return;
      }
      return lastPage.page + 1;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: isEnabled,
  });

  const eventsList = useMemo(
    () => events?.pages.flatMap((page) => page.items),
    [events],
  );

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return {
    eventsList,
    isLoadingEvents,
    isFetchingEvents,
    isFetchingNextPage,
    hasNextPage,

    fetchNextPage,
    inViewRef,
  };
}
