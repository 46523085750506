export const QUERY_KEY_NOTIFICATIONS = "QUERY_KEY_NOTIFICATIONS";
export const QUERY_KEY_RESIDENT_CENTER_INFO = "QUERY_KEY_RESIDENT_CENTER_INFO";
export const QUERY_KEY_POI_CATEGORIES = "QUERY_KEY_POI_CATEGORIES";
export const QUERY_KEY_MAP_POIS = "QUERY_KEY_MAP_POIS";

export const QUERY_KEY_TENANT_CONFIG = "QUERY_KEY_TENANT_CONFIG";
export const QUERY_KEY_TENANT_THEME = "QUERY_KEY_TENANT_THEME";

export const QUERY_KEY_EVENTS_SEARCH = "QUERY_KEY_EVENTS_SEARCH";
export const QUERY_KEY_EVENT_TICKETS_DATA = "QUERY_KEY_EVENT_TICKETS_DATA";
export const QUERY_KEY_EVENT_DETAILS = "QUERY_KEY_EVENT_DETAILS";
export const QUERY_KEY_EVENT_RECURRING_DATES =
  "QUERY_KEY_EVENT_RECURRING_DATES";
export const QUERY_KEY_EVENT_CALENDAR_DATES = "QUERY_KEY_EVENT_CALENDAR_DATES";
export const QUERY_KEY_EVENT_CATEGORY_LIST = "QUERY_KEY_EVENT_CATEGORY_LIST";

export const QUERY_KEY_USER_AMENITIES = "QUERY_KEY_USER_AMENITIES";
export const QUERY_KEY_USER_LISTING_CATEGORIES =
  "QUERY_KEY_USER_LISTING_CATEGORIES";
export const QUERY_KEY_USER_DETAILS = "QUERY_KEY_USER_DETAILS";
export const QUERY_KEY_USER_COMMUNITY_GROUPS =
  "QUERY_KEY_USER_COMMUNITY_GROUPS";
export const QUERY_KEY_USER_VENUE_DETAILS = "QUERY_KEY_USER_VENUE_DETAILS";
export const QUERY_KEY_USER_VENUE_AVAILABILITY =
  "QUERY_KEY_USER_VENUE_AVAILABILITY";
export const QUERY_KEY_USER_VENUES_RATES = "QUERY_KEY_USER_VENUES_RATES";
export const QUERY_KEY_USER_VENUE_SPACES_RATES =
  "QUERY_KEY_USER_VENUE_SPACES_RATES";
export const QUERY_KEY_VENUE_SPACE_AVAILABILITY =
  "QUERY_KEY_VENUE_SPACE_AVAILABILITY";

export const QUERY_KEY_MEMBERSHIP_LIST = "QUERY_KEY_MEMBERSHIP_LIST";
export const QUERY_KEY_MEMBERSHIP_DETAILS = "QUERY_KEY_MEMBERSHIP_DETAILS";
export const QUERY_KEY_MEMBERSHIP_PAYMENT = "QUERY_KEY_MEMBERSHIP_PAYMENT";

export const QUERY_KEY_GENERIC_ROW_ITEMS = "QUERY_KEY_GENERIC_ROW_ITEMS";
export const QUERY_KEY_SERVICE_DETAILS = "QUERY_KEY_SERVICE_DETAILS";

export const QUERY_KEY_CMS_MAIN_PAGES = "QUERY_KEY_CMS_MAIN_PAGES";
export const QUERY_KEY_CMS_DETAIL_PAGES = "QUERY_KEY_CMS_DETAIL_PAGES";
export const QUERY_KEY_NAV_BAR_ITEMS = "QUERY_KEY_NAV_BAR_ITEMS";
export const QUERY_KEY_TENANT_FOOTER = "QUERY_KEY_TENANT_FOOTER";
export const QUERY_KEY_ARTICLES = "QUERY_KEY_ARTICLES";
