import { getEventDetails } from "@App/api/event";
import { QUERY_KEY_EVENT_DETAILS } from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";

type UseEventDetailsParams = {
  eventId?: string | null;
};

export default function useEventDetails(params: UseEventDetailsParams) {
  const { eventId } = params;

  // See if this screws up the sub
  if (!eventId) {
    return { event: undefined, isLoadingEvent: false };
  }

  const { data: event, isLoading: isLoadingEvent } = useQuery({
    queryKey: [QUERY_KEY_EVENT_DETAILS, eventId],
    queryFn: () => getEventDetails(eventId),
    enabled: !!eventId,
    select: (data) => data.data,
  });

  return { event, isLoadingEvent };
}
