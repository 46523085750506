import { DEFAULT_MOBILE_MEDIA_QUERY } from "@App/constants/appConstants";
import { useEventCategories, usePassedEventCategory } from "@App/hooks";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@Store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useCalendarEvents from "./useCalendarEvents";

const CalendarLogic = () => {
  const SCROLL_AMOUNT = 300;

  // TODO: Refactor to tanstack
  const { userInfo } = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const [searchParams] = useSearchParams();
  const managedByEntityId = searchParams.get("calendarEntity");

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showCategoryLeftScrollButton, setShowCategoryLeftScrollButton] =
    useState(false);
  const [showCategoryRightScrollButton, setShowCategoryRightScrollButton] =
    useState(false);

  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();

  const { eventCategories, isLoadingEventCategories } = useEventCategories();

  const {
    eventCalendarDates,
    isLoadingEventCalendarDates,
    eventsList,
    isLoadingEvents,
    isFetchingCalendarEvents,
    eventsListInViewRef,
    eventsListHasNextPage,
  } = useCalendarEvents({
    calendarCurrentMonth,
    selectedDate,
    selectedCategory,
    managedByEntityId,
    searchKeyword: searchText,
  });

  // Sets event category from search params
  usePassedEventCategory(eventCategories, setSelectedCategory);

  const handleMonthChange = ({
    date,
  }: {
    date: Date;
    isRefresh?: boolean;
    managedById?: string | null;
  }) => {
    const isSameMonth =
      `${date.getFullYear()}-${date.getMonth()}` ===
      `${calendarCurrentMonth.getFullYear()}-${calendarCurrentMonth.getMonth()}`;

    if (!isSameMonth) {
      setCalendarCurrentMonth(date);
    }
  };

  // On mount
  useEffect(() => {
    const mediaQuery = window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY);

    // Painful to type
    const handleTabletChange = (e: any) => {
      setIsSmallScreen(e.matches);
    };
    handleTabletChange(mediaQuery);
    mediaQuery.addEventListener("change", handleTabletChange);
    return () => {
      mediaQuery.removeEventListener("change", handleTabletChange);
    };
  }, [setIsSmallScreen]);

  useEffect(() => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters",
    );
    if (filterHtmlElement) {
      setShowCategoryRightScrollButton(
        filterHtmlElement.scrollWidth !== filterHtmlElement.clientWidth,
      );
    }
  }, [eventCategories]);

  const handleScroll = (isLeft?: boolean) => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters",
    );

    if (filterHtmlElement) {
      isLeft
        ? (filterHtmlElement.scrollLeft -= SCROLL_AMOUNT)
        : (filterHtmlElement.scrollLeft += SCROLL_AMOUNT);
      setShowCategoryLeftScrollButton(
        isLeft
          ? filterHtmlElement.scrollLeft - SCROLL_AMOUNT > 0
          : filterHtmlElement.scrollWidth > filterHtmlElement.clientWidth,
      );
    }
  };

  return {
    isLoadingEvents,
    isUserLogged: !!userInfo?.firstName,
    isSmallScreen,
    eventsListHasNextPage,

    categoryFilters: eventCategories,

    eventCalendarDates,

    events: eventsList,
    eventsListInViewRef,
    isFetchingCalendarEvents,

    selectedDate,
    setSelectedDate,
    selectedCategory,
    setSelectedCategory,
    showCategoryLeftScrollButton,
    setShowCategoryLeftScrollButton,
    searchText,
    setSearchText,
    showCategoryRightScrollButton,

    handleMonthChange,
    handleScroll,
  };
};

export default CalendarLogic;
