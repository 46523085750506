import { format, addMonths } from "date-fns";

export default function getFormattedDateRange(
  startDate: Date,
  endDate?: Date,
  useDefaultStartDate = true
) {
  const dateRange = {
    startDate: getFormattedStartDate(startDate, useDefaultStartDate),
    endDate: getFormattedEndDate(endDate),
  };

  return dateRange;
}

// Either search from the first day of the month or the current day
function getStartDay(startDate: Date) {
  const isCurrentMonth =
    format(startDate, "yyyy/MM") === format(new Date(), "yyyy/MM");

  const startDay = isCurrentMonth ? new Date().getDate() : 1;
  return startDay;
}

// Default is to look up to 12 months ahead
function getEndDate(endDate?: Date) {
  return endDate ?? addMonths(new Date(), 12);
}

// Use either day sent in or the first day of the month
// Set the time to midnight to get all events for that day
function getFormattedStartDate(startDate: Date, useDefault = true) {
  if (useDefault) {
    startDate.setDate(getStartDay(startDate));
    startDate.setHours(0, 0, 0);
  }

  return format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
}

// Remember to set the end day appropriately from the caller to get the whole month
function getFormattedEndDate(endDate?: Date) {
  endDate = getEndDate(endDate);
  endDate.setHours(23, 59, 59);

  return format(endDate, "yyyy-MM-dd'T'HH:mm:ss");
}
